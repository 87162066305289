const init = {
    createKeys: function(component){
        return new Promise((resolve)=>{
            for(let key in component.$attrs.value){
                component.$set(component.api_data,key,{})
            }
            resolve()
        })
    },
    syncFactors: function(component){
        return new Promise((resolve)=>{

            let factors = component.$attrs.value.factors
            for(let factorKey in factors){
                let value = factors[factorKey]
                component.$set(component.api_data.factors,factorKey,value)
            }
            resolve()
        })
    },
    syncValues: function(component){
        return new Promise((resolve)=>{

            let values = component.$attrs.value.values
            for(let valueKey in values){
                let value = values[valueKey]
                component.$set(component.api_data.values,valueKey,value)
            }
            resolve()
        })
    }
}

module.exports = init