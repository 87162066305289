<template>
<div :qid="question.id" :data-test-question-id="question.id">
    <div v-if="debug" class="debug" v-html="question.id"/>
    <div data-test-question-section="header" v-html="question.header.text[language]" style="color:#261062; font-weight: bold;"/>
    <!-- <Tooltip v-if="infoTrigger && infoTrigger.triggers.body" :trigger="infoTrigger.triggers.body" :content="infoTrigger.content"/> -->
    <div data-test-question-section="body" v-html="question.body.text[language]" style="color:#261062; font-weight: bold;"/>
    <div style="margin: 5px; font-size: 0.8em;" v-html="question.subtext.text[language]"/>
    
    <v-list dense v-for="(group, groupIndex) in question.groups" :key="'qid_'+question.id+'_gid_'+group.id">
        <v-list-item v-if="group.type=='radio'">
            <v-list-item-content>
                <v-list-item-title>
                    {{group.text[language]}}
                </v-list-item-title>
                <v-radio-group v-model="form.group[group.id].value" style="margin-top: 0px;">
                    <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                        <v-list-item-content style="overflow: visible;">
                            <v-radio dense :value="choice.value ? choice.value : choice.id">
                                <span slot="label" v-html="choice.text[language]" :data-test-question-choice="question.id+'_'+group.id+'_'+choice.id"/>
                            </v-radio>
                            <v-text-field v-if="form.group[group.id].value==choice.value && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-radio-group>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="group.type=='number'">
            <v-list-item-content style="overflow: visible;">
                <v-list-item-title>
                    {{group.text[language]}}
                </v-list-item-title>
                <v-list-item-content>
                    <v-container v-if="group.subType=='slider'">
                        <v-row>
                            <v-col style="max-width: 200px;">
                                <v-slider
                                    v-model="form.group[group.id].value"
                                    :min="group.constraints ? group.constraints.min : 0"
                                    :max="group.constraints ? group.constraints.max : 100"
                                    thumb-label="always"
                                    style="position: relative; top: 25px; max-width: 200px;"
                                ></v-slider>
                            </v-col>
                            <v-col style="max-width: 200px;">                                        
                                <v-text-field v-model="form.group[group.id].value" type="number" :min="group.constraints ? group.constraints.min : 0" :max="group.constraints ? group.constraints.max : 100" outlined dense/>
                            </v-col>
                        </v-row>
                    </v-container>

                    <table class="numberInput" v-else style="max-width: 200px;">
                        <tbody>
                            <tr>
                                <th>
                                    <v-btn text style="position: relative; left: 8px;" @click="stepChange('-', groupIndex, group.id)">-</v-btn>
                                </th>
                                <th>
                                    <v-text-field v-model="form.group[group.id].value" type="number" :min="group.constraints ? group.constraints.min : 0" :max="group.constraints ? group.constraints.max : 100" outlined dense/>
                                </th>
                                <th>
                                    <v-btn text style="position: relative; left: -8px;" @click="stepChange('+', groupIndex, group.id)">+</v-btn>
                                </th>
                            </tr>
                        </tbody>

                    </table>

                </v-list-item-content>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="group.type=='checkbox'">
            <v-list-item-content>
                <v-list-item-title>
                    {{group.text[language]}}
                </v-list-item-title>
                <template v-for="(choice) in group.choices">
                    <v-list-item :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                        <v-list-item-content style="overflow: visible;">
                            <v-container :dense="true" :no-gutters="true" style="padding: 0px;">
                                <v-row>
                                    <v-col>
                                        <v-checkbox dense v-model="form.group[group.id].value" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)">
                                            <span slot="label" v-html="choice.text[language]" :data-test-question-choice="question.id+'_'+group.id+'_'+choice.id"/>
                                        </v-checkbox>
                                        <v-text-field v-if="form.group[group.id].value.includes(choice.value) && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                                    </v-col>
                                    <v-col v-if="choice.side" align-self="start">
                                        <div v-show="form.group[group.id].value.includes(choice.value)" v-html="choice.side[language]"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list-item-content>
        </v-list-item>

        <template v-else>
            Unknown group type: {{group.type}}
        </template>
    </v-list>
    

    <v-dialog v-model="ui.info.display" width="50%">
        <v-card>
            <v-card-title/>
            <v-card-text>
                <div :qid="question.id" v-html="ui.info.content"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                    <v-btn @click="ui.info.display=false">close</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
// import Tooltip from './Tooltip'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        subType: {
            type: String,
            required: false,
            default: null
        },
        question: {
            type: Object,
            required: true
        },
        info: {
            type: Object,
            required: false,
            default: null
        },
        images: {
            type: Object,
            required: false,
            default: null
        },
        constraints: {
            type: Object,
            required: false,
            default: null
        },
        language: {
            type: String,
            required: false,
            default: 'en'
        }
    },    
    components: {
        // Tooltip
    },
    created: function(){
        this.init()
    },
    mounted: function(){
        let self = this
        let iconClasses = [
            'v-icon',
            'notranslate',
            'mdi',
            'mdi-information-outline',
            'theme--light'
        ]
        let icons = document.querySelectorAll("[qid='"+this.question.id+"'] [ui-icon='info']")
        for(let i=0; i<icons.length; i++){
            let elem = icons[i]
            let target = elem.getAttribute('info-target')
            for(let c=0; c<iconClasses.length; c++){
                elem.classList.add(iconClasses[c])
                elem.removeEventListener("click",()=>{self.infoClick(target)})
                elem.addEventListener("click",()=>{self.infoClick(target)})
            }
        }

        let links = document.querySelectorAll("[qid='"+this.question.id+"'] [ui-link='info']")
        for(let i=0; i<links.length; i++){
            let elem = links[i]
            let target = elem.getAttribute('info-target')
            elem.removeEventListener("click",()=>{self.infoClick(target)})
            elem.addEventListener("click",()=>{self.infoClick(target)})
        }

        self.linkImages()
    },
    data: function(){
        return {
            timer: null,
            form: {
                group: {}
            },
            clicked: {
                group: null,
                choice: null,
                checked: null
            },
            ui: {
                info: {
                    display: false,
                    content: null
                }
            }
        }
    },
    methods: {
        init: function(){
            let form = this.form
            let question = this.question
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]

                switch(group.type){
                    default:
                        this.$set(form.group, group.id, {
                            value: null,
                            others: {}
                        })
                    break;

                    case"checkbox":
                        this.$set(form.group, group.id, {
                            value: [],
                            others: {}
                        })
                    break;

                }
            }
        },
        stepChange: function(operator, groupIndex, group_id){
            let form = this.form
            let min = this.question.groups[groupIndex].constraints ? this.question.groups[groupIndex].constraints.min : 0
            let max = this.question.groups[groupIndex].constraints ? this.question.groups[groupIndex].constraints.max : 100

            if(form.group[group_id].value===null){
                form.group[group_id].value = 0
            }else{
                if(operator=='-' && form.group[group_id].value>min){
                    form.group[group_id].value--
                }
    
                if(operator=='+' && form.group[group_id].value<max){
                    form.group[group_id].value++
                }
            }
        },
        infoClick: function(target){
            let self = this
            let info = this.info
            if(info[target]){
                this.ui.info.content = info[target].content.en
                this.ui.info.display = !this.ui.info.display
            }

            setTimeout(function(){
                self.linkImages()
            },800)            
        },
        linkImages: function(){
            let self = this
            let imageElems = document.querySelectorAll("[qid='"+this.question.id+"'] img")
            for(let i=0; i<imageElems.length; i++){
                let elem = imageElems[i]
                let target = elem.getAttribute('src')
                let src = self.images[target]

                if(src){
                    elem.src = self.images[target]
                }
            }            
        },
        checkboxHandler: function(group, choice){
            this.clicked.group = group
            this.clicked.choice = choice
            this.clicked.checked = false

            if(this.form.group[group.id].value!=null && this.form.group[group.id].value.includes(choice.value)){
                if(choice.exclusive){
                    this.form.group[group.id].value = [choice.value]
                }else{
                    let exclusives = []
                    let filtered = []
                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        if(choice.exclusive){
                            exclusives.push(choice.value)
                        }
                    }
                    for(let v=0; v<this.form.group[group.id].value.length; v++){
                        let value = this.form.group[group.id].value[v]
                        if(!exclusives.includes(value)){
                            filtered.push(value)
                        }
                    }
                    this.form.group[group.id].value = filtered
                }

                this.clicked.checked = this.form.group[group.id].value.includes(choice.value)
            }
        }
    },
    computed: {
        display: function(){
            return this.question.display
        }
        // infoTrigger: function(){
        //     let question = this.question
        //     let info = this.info
        //     if(info){
        //         for(let key in info){
        //             let infoItem = info[key]
        //             if(infoItem.triggers[question.id]){
        //                 return {triggers: infoItem.triggers[question.id], content: infoItem.content.en}
        //             }
        //         }
        //     }
        //     return null
        // }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                let self = this;
                    let form = self.form
                    let question = self.question
                    let clicked = self.clicked
                    self.$emit('input',form)
                    self.$emit('questionUpdate', {question, form, clicked})
            }
        },
        display: function(){
            if(!this.display){
                this.init()
            }
        }
    }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.numberInput button{
    border: 1px solid lightgrey;
    height: 42px !important;
    min-width: 40px !important;
    position: relative;
    top: -12px;
}
</style>