<template>
    <v-container :data-test-recommendation="recommendationID" :key="'recommendation_'+recommendationID" v-if="ui_phase['ui_'+recommendationID]" :data-test-ui-phase="recommendationID">
        <div v-if="debug" class="debug">
            ui_{{recommendationID}}
        </div>
        <v-row>
            <v-col>
                <div class="cua-darkblue recommendation-title" style="font-size: 18pt; font-weight: 500;">
                    {{recommendation.body[language]}}
                </div>
            </v-col>
        </v-row>

        <v-row :key="'treatment_heading_'+recommendationID">
            <v-col class="recommendation-header">
                <Str :index="'recommendations > column > treatment option'"/>
            </v-col>
            <v-col class="recommendation-header" align="center">
                <Str :index="'recommendations > column > cua recommendations'"/>
            </v-col>
            <v-col class="recommendation-header" align="center">
                <Str :index="'recommendations > column > select treatment'"/>
            </v-col>
        </v-row>

        <template v-for="(treatment, treatmentIndex) in recommendation.treatments" >
            <v-row :data-test-treatment="treatment.kt_id ? treatment.kt_id : treatment.kt_na" :key="'recommendation_'+recommendationID+'_treatment_'+treatmentIndex" :style="selection.includes(treatment.kt_id) ? '' : 'border-bottom: 1px solid lightgrey;'">
                <v-col>
                    <div v-if="debug" class="debug">
                        <template v-if="treatment.kt_id!=null">
                        kt_id: {{treatment.kt_id}}
                        </template>
                        <template v-else>
                        kt_na: {{treatment.kt_na}}
                        </template>
                    </div>                    
                    <div v-html="treatment.option[language]"/>
                </v-col>
                <v-col align="center">
                    <Str :index="'recommendations > level'"/>: <Str v-if="treatment.recommendation.level=='expert'" :index="'recommendations > expert'"/> <template v-else>{{treatment.recommendation.level}}</template> | <Str :index="'recommendations > strength > '+treatment.recommendation.strength"/>
                </v-col>
                <v-col align="center">
                    <div style="display:inline-block;">
                    <v-checkbox dense v-if="treatment.kt_id!=null" v-model="selection" :value="treatment.kt_id">
                        <template v-slot:label>
                            <v-icon :color="selection.includes(treatment.kt_id) ? '#00A8CB' : 'darkgrey'">mdi-eye</v-icon>
                        </template>
                    </v-checkbox>
                    </div>
                </v-col>
            </v-row>
            
            <transition name='slide' :key="'recommendation_'+recommendationID+'_treatment_'+treatmentIndex+'_details'">
                <section v-if="kt_content.treatments[treatment.kt_id] && treatment.kt_id!=null && selection.includes(treatment.kt_id)" :data-test-kt-id="treatment.kt_id">
                    <!-- <v-row>
                        <v-col align="center">
                            <div class="cua-treatment-header" v-html="kt_content.treatments[treatment.kt_id].content.title[language]"/>
                        </v-col>
                    </v-row> -->
                    <v-row style="border-bottom: 1px solid lightgrey;">
                        <v-col>
                            <Treatment class="treatment" :kt_content="kt_content" :kt_id="treatment.kt_id" :language="language"/>
                        </v-col>
                    </v-row>
                </section>
            </transition>
        </template>

        
        <v-row v-if="recommendation.footer!==undefined">
            <v-col v-html="recommendation.footer[language]"/>
        </v-row>
    </v-container>
</template>

<script>
import Treatment from './treatment'


export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        recommendationID: {
            type: String,
            required: true
        },
        recommendation: {
            type: Object,
            required: true
        },
        kt_content: {
            type: Object,
            required: true
        },
        ui_phase: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: "en"
        }
    },
    components: {
        Treatment
    },
    data: function(){
        return {
            selection: []
        }
    },
    computed: {
        KT_selection: function(){
            return this.$attrs.value
        }
    },
    watch: {
        selection: function(){
            this.$emit('input',this.selection)
        },
        KT_selection: function(){
            this.selection = this.KT_selection
        }
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.2s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}


.recommendation-header{
    font-weight: bold;
    color: #00A8CB;
}

.cua-darkblue{
    color: #261062;
}

.cua-red{
    color: #E9212E;
}

.treatment{
    padding: 15px;
}



@media only screen and (max-width: 600px) {
    .treatment{
        padding: 0px;
    }
}
</style>